import React, { useEffect, useState, useRef } from "react"
// import { Link } from "gatsby" // for internal site links
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import { BackgroundImage } from "gatsby-background-image"
// Helpers
import { animate, cutOffExcerpt, trackCta } from "src/helpers"

// Components
import BackgroundBlobs from "src/components/images/BackgroundBlobs"
import Blogs from "src/components/blogs"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Stars from "src/components/stars"
import SignupButton from "src/components/signupButton"

// Data
import logos from "src/content/home/logos"
import capterraReviews from "src/content/home/capterraReviews"

// Styles
import "src/styles/templates/home.scss"
import "src/styles/animations/home.scss"
import "src/styles/components/annotations.scss"
import "src/styles/components/onetrust.scss"

// SEO
const title =
  "Timetastic - Online staff leave & holiday planner for modern companies"
const description =
  "Timetastic is the super-simple staff leave planner used by over 100,000 people to organise their time off work."

// JSX
const Home = (props) => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false)
  const reviews = props.data.allReviews.nodes
  const reviewStats = reviews[0].stats
  const posts = props.data.allBlogPosts.nodes

  // Animate any elements with a class of 'is-unanimated' once the background images are loaded
  useEffect(() => {
    if (backgroundLoaded) {
      animate()
    }
  }, [backgroundLoaded])

  return (
    <div>
      <SEO
        title={title}
        useTitleTemplate={false}
        description={description}
        hrefLangs={{
          EnHref: "https://timetastic.co.uk/",
          UsaHref: "https://timetastic.us/",
          UkHref: "https://timetastic.co.uk/",
        }}
      />
      <Layout>
        <Header />
        <FooterCta />
        <div>
          <main className="c-home-main">
            {/* Homepage Hero */}
            <section className="c-section c-home-hero u-text-centre" id="hero">
              <div className="u-inner u-inner--l c-home-hero__inner">
                <h1 className="h h1 c-home-hero__title">
                  “By a long shot, it’s the <span>best</span>{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    staff holiday planner
                  </span>{" "}
                  for teams <span>and small companies</span>”
                </h1>
              </div>
              <div className="u-inner c-home-hero__inner">
                <p className="c-home-hero__summary">
                  Switching to Timetastic makes booking time off work a breeze.
                  No more dealing with paper forms and updating messy
                  spreadsheets. You get a holiday tracker with a centralised and
                  up-to-date record of absences, so you can plan better, and
                  work smarter.
                </p>
                <div className="c-home-hero__cta">
                  <SignupButton
                    buttonCopy="Give Timetastic a try"
                    trackCtaLabel="signupclick"
                  />

                  <p className="c-free-trial__no-link">
                    Free for one month - no card required
                  </p>
                </div>
                <h2 className="h h4">Want to see how it works?</h2>
                <a
                  className="u-em-link"
                  href="https://timetastic.ewebinar.com/webinar/timetastic-product-demo-6394"
                  target="blank"
                  rel="nofollow noreferrer noopener"
                  onClick={() => {
                    trackCta("eWebinar")
                  }}
                >
                  <span>Watch our 10 minute webinar</span>
                </a>
                <span> or </span>
                <a
                  className="u-em-link"
                  href="https://calendly.com/sarah-timetastic/timetastic-demo"
                  target="blank"
                  rel="nofollow noreferrer noopener"
                  onClick={() => {
                    trackCta("BookDemo")
                  }}
                >
                  <span>book a live demo</span>
                </a>
                <div className="c-home-quotes">
                  <div className="c-home-quote">
                    <p>“An absolute no-brainer”</p>
                  </div>
                  <div className="c-home-quote">
                    <p>“Fantastic super simple product!”</p>
                  </div>
                  <div className="c-home-quote">
                    <p>“Staff here love it”</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="c-section c-home-xyz">
              <BackgroundBlobs setBackgroundLoaded={setBackgroundLoaded}>
                <div className="u-inner u-inner--l u-inner--hero is-unanimated">
                  <div className="c-home-hero-grid__item u-text-center">
                    <div className="c-home-grid__annotation c-home-hero-grid__image--mobile-s">
                      <span className="c-home-grid__annotation--text c-home-grid__annotation--top">
                        Book leave via mobile
                      </span>
                      <StaticImage
                        src="../images/hero-mobile-booking.jpg"
                        width={359}
                        alt="Mobile booking"
                        layout="constrained"
                        loading="eager"
                      />
                    </div>
                    <div className="c-home-grid__annotation c-home-hero-grid__image--mobile">
                      <span className="c-home-grid__annotation--text c-home-grid__annotation--top">
                        Book leave via mobile
                      </span>
                      <StaticImage
                        src="../images/hero-mobile-booking.jpg"
                        width={304}
                        alt="Mobile booking"
                        layout="constrained"
                        loading="eager"
                        className="c-home-hero-grid__image"
                      />
                    </div>
                    <div className="c-home-grid__annotation c-home-hero-grid__image--wallchart">
                      <span className="c-home-grid__annotation--text c-home-grid__annotation--top">
                        See who’s off on the Wallchart
                      </span>
                      <StaticImage
                        src="../images/hero-wallchart.jpg"
                        width={881}
                        alt="Wallchart"
                        layout="constrained"
                        loading="eager"
                      />
                    </div>
                    <div className="c-home-grid__annotation c-home-hero-grid__image--request-email">
                      <StaticImage
                        src="../images/hero-request-email.jpg"
                        width={360}
                        alt="Leave request email"
                        layout="constrained"
                        loading="eager"
                      />
                      <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom">
                        Easy request approval
                      </span>
                    </div>
                    <div className="c-home-grid__annotation c-home-hero-grid__image--week-ahead">
                      <StaticImage
                        src="../images/hero-week-ahead.jpg"
                        width={506}
                        alt="Your week ahead"
                        layout="constrained"
                        loading="eager"
                        className="c-home-hero-grid__image--week-ahead"
                      />
                      <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom">
                        Daily or weekly digest
                      </span>
                    </div>
                  </div>
                </div>
              </BackgroundBlobs>
            </section>

            <section className="c-section u-text-centre c-home-video">
              <div className="u-inner u-inner--l u-inner--hero is-unanimated">
                <div className="c-video c-video__explainer">
                  <h2 className="h h2 u-text-centre">
                    See Timetastic in action
                  </h2>
                  <div className="c-video__player">
                    <iframe
                      title="See Timetastic in action"
                      src="https://player.vimeo.com/video/880192428?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                      allowFullScreen={true}
                      loading="lazy"
                    ></iframe>
                  </div>
                  <SignupButton
                    buttonCopy="Start free trial"
                    trackCtaLabel="HomeVideoSignup"
                  />
                  <p className="c-free-trial__no-link">
                    Free for one month - no card required
                  </p>
                </div>
              </div>
            </section>

            <section
              className="c-section c-section--grey c-home-screenshots is-unanimated"
              id="screenshots"
            >
              <div className="u-inner u-inner--l c-home-screenshots__inner">
                <div>
                  <h2 className="h h2 c-home-screenshots__title">
                    No spreadsheets.
                    <br />
                    No forms.
                  </h2>
                  <p>
                    Say goodbye to tedious spreadsheets and forms. The employee
                    holiday tracker streamlines the entire process for you.
                    Simply log in, request your holiday, and let Timetastic
                    handle the rest. Save yourself some precious time and
                    energy.
                  </p>
                  <SignupButton
                    buttonCopy="Start free trial"
                    trackCtaLabel="HomeNoSpreadsheetsSignupclick"
                  />

                  <p className="c-free-trial__no-link">
                    Free for one month - no card required
                  </p>
                </div>

                <div className="c-home-screenshots__tablet">
                  <StaticImage
                    src="../images/no-spreadsheets-tablet.jpg"
                    width={780}
                    alt="Tablet wallchart"
                    placeholder="blurred"
                    layout="constrained"
                  />
                  <StaticImage
                    src="../images/no-spreadsheets-mobile.jpg"
                    width={197}
                    alt="Mobile booking"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-home-screenshots__mobile"
                  />
                </div>
                <div className="c-home-screenshots__mobile-s">
                  <StaticImage
                    src="../images/no-spreadsheets-mobile-s.jpg"
                    width={359}
                    alt="Mobile calendar"
                    placeholder="blurred"
                    layout="constrained"
                  />
                </div>
              </div>
            </section>

            {/* Personal Calendars */}

            <section className="c-section">
              <div className="u-inner is-animated u-text-centre-desktop">
                <h2 className="h h2 u-text-centre-desktop">
                  A personal holiday tracker for{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    everyone
                  </span>
                </h2>
                <div className="c-home-calendars__intro">
                  <p>
                     Each team member receives their own staff holiday calendar,
                    giving them a complete overview of their annual leave. You
                    can plan ahead, review holiday allowance usage, and keep
                    track of remaining time off with real-time updates.
                  </p>
                </div>
              </div>
              <div className="c-section c-home-calendars is-animated">
                <BackgroundBlobs blobType="calendarBlobs">
                  <div className="u-inner u-inner--l">
                    <div className="c-home-calendars-grid__item">
                      <div className="c-home-grid__annotation c-home-calendars-grid__image-remaining">
                        <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                          Keep track of allowances
                        </span>
                        <StaticImage
                          src="../images/calendars-remaining.jpg"
                          width={293}
                          alt="Remaining allowance"
                          placeholder="blurred"
                          layout="constrained"
                          className="c-home-calendars-grid__image"
                        />
                      </div>
                      <div className="c-home-grid__annotation c-home-calendars-grid__image-months">
                        <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                          Absence at a glance
                        </span>
                        <StaticImage
                          src="../images/calendars-months.jpg"
                          width={585}
                          alt="Calendar months"
                          placeholder="blurred"
                          layout="constrained"
                          className="c-home-calendars-grid__image"
                        />
                      </div>
                      <div className="c-home-grid__annotation c-home-calendars-grid__image-months-2">
                        <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                          Absence at a glance
                        </span>
                        <StaticImage
                          src="../images/calendars-months-2.jpg"
                          width={538}
                          alt="Calendar months"
                          placeholder="blurred"
                          layout="constrained"
                          className="c-home-calendars-grid__image"
                        />
                      </div>
                      <div className="c-home-grid__annotation c-home-calendars-grid__image-summary">
                        <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                          Detailed leave summaries
                        </span>
                        <StaticImage
                          src="../images/calendars-summary.jpg"
                          width={586}
                          alt="Leave summary"
                          placeholder="blurred"
                          layout="constrained"
                          className="c-home-calendars-grid__image"
                        />
                      </div>
                      <div className="c-home-grid__annotation c-home-calendars-grid__image-approved">
                        <StaticImage
                          src="../images/calendars-approved.jpg"
                          width={586}
                          alt="Request approved"
                          placeholder="blurred"
                          layout="constrained"
                          className="c-home-calendars-grid__image"
                        />
                        <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom c-home-grid__annotation--hide">
                          Instant email notifications
                        </span>
                      </div>
                      <StaticImage
                        src="../images/calendars-breakdown.jpg"
                        width={586}
                        alt="Your time off"
                        placeholder="blurred"
                        layout="constrained"
                        className="c-home-calendars-grid__image c-home-calendars-grid__image-breakdown"
                      />
                      <StaticImage
                        src="../images/calendars-trends.jpg"
                        width={586}
                        alt="Annual trends"
                        placeholder="blurred"
                        layout="constrained"
                        className="c-home-calendars-grid__image c-home-calendars-grid__image-trends"
                      />
                    </div>
                  </div>
                </BackgroundBlobs>
              </div>
            </section>

            {/* Reviews list */}
            <section
              className="c-section c-home-reviews u-text-center u-text-left-mobile"
              id="reviews"
            >
              <div className="u-inner is-unanimated">
                <h2 className="h h2 c-home-reviews__title">
                  People really{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    like using Timetastic
                  </span>
                </h2>
              </div>
              <div className="u-inner u-inner--800 is-unanimated">
                <p className="">
                  Over 200,000 users. Instead of messing around with
                  spreadsheets, you and all your staff can easily access the
                  employee holiday tracker to check their allowance and
                  conveniently book time off with just a few taps.
                </p>

                <ul className="c-reviews-list">
                  {capterraReviews.map((review, index) => (
                    <li
                      className="c-reviews-list__item is-unanimated"
                      key={`review-l-${index}`}
                    >
                      <div className="c-reviews-list__content">
                        <p>‘{review.review}’</p>
                      </div>
                      <div className="c-reviews-list__avatar">
                        <img
                          loading="lazy"
                          src={review.avatar}
                          width="48px"
                          height="48px"
                          alt={review.reviewer}
                        />
                      </div>
                      <div className="c-reviews-list__reviewer">
                        <a
                          className="c-reviews-list__link"
                          href={review.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            trackCta("TestimonialsOutboundLink")
                          }}
                        >
                          {review.reviewer}
                        </a>
                      </div>
                    </li>
                  ))}
                  <li className="c-reviews-list__item is-unanimated">
                    <a
                      className="u-em-link u-hover-link"
                      href="/customers/"
                      onClick={() => {
                        trackCta("HomeCustomers")
                      }}
                    >
                      <span>
                        There’s more... find out how Timetastic is working for
                        thousands of companies just like yours
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </section>

            {/* Reviews carousel */}
            <section className="c-section c-home-reviews-grid is-unanimated">
              <div className="u-inner u-inner--l">
                <div className="c-reviews-section">
                  <div className="c-reviews-section__top u-text-center u-text-left-mobile">
                    <h2 className="h h3">
                      The 5 star reviews just keep coming
                    </h2>
                    <div
                      aria-describedby={`Rated: ${
                        Math.ceil(reviewStats.average_rating * 2) / 2
                      } stars out of 5`}
                      style={{ color: "#fec000" }}
                    >
                      <Stars
                        namespace="total-reviews"
                        numberOfStars={5}
                        // numberOfStars={parseFloat(
                        //   Math.ceil(reviewStats.average_rating * 2) / 2
                        // )}
                        size={24}
                      />
                    </div>
                    <div>
                      <a
                        className="u-em-link u-hover-link"
                        href="https://www.reviews.co.uk/company-reviews/store/timetastic"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          trackCta("HomeReviewsOutboundLink")
                        }}
                      >
                        <span>
                          View all {reviewStats.total_reviews.toLocaleString()}{" "}
                          reviews{" "}
                        </span>
                        <svg
                          aria-hidden="true"
                          className="c-more-icon"
                          focussable="false"
                          height="16"
                          viewBox="0 0 20 16"
                          width="20"
                        >
                          <use xlinkHref="#svg-arrow" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div className="c-reviews">
                    {reviews.map((review, index) => (
                      <div
                        className="c-review"
                        key={`review-${review.store_review_id}-${review.user_id}`}
                      >
                        <div className="c-review__content">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: cutOffExcerpt(review.comments, 112),
                            }}
                          ></p>
                        </div>
                        <div
                          aria-describedby={`Rated: ${review.rating} stars out of 5`}
                          className="c-review__stars"
                        >
                          <Stars
                            namespace={`${review.store_review_id}-${review.user_id}`}
                            numberOfStars={parseFloat(review.rating)}
                          />
                        </div>
                        <div className="c-review__reviewer">
                          {review.reviewer.first_name}{" "}
                          {review.reviewer.last_name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
            {/* Customers CTA */}
            <FreeTrial
              body="Doctors, web design agencies, car dealers, online retailers, charities, coffee roasters - over 8,000 businesses just like yours manage their team’s absence with Timetastic."
              buttonCopy="Start your free trial"
              title="200,000+ people use Timetastic to book time off work"
              trackCtaLabel="HomeSignUp"
              imgclassName="c-free-trial__user-graph"
            />
          </main>
        </div>
        <Footer />
      </Layout>
      {/* Schema.org tags */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "Timetastic",
            legalName: "Timetastic Ltd",
            url: "https://timetastic.co.uk",
            logo: "https://timetastic.co.uk/images/timetastic_logo_blue.png",
            foundingDate: "2012",
            vatID: "GB196022312",
            address: {
              "@type": "PostalAddress",
              streetAddress: "143, 19 Lever St",
              addressLocality: "Manchester",
              addressRegion: "Greater Manchester",
              postalCode: "M1 1AN",
              addressCountry: "GB",
            },
            sameAs: [
              "https://twitter.com/timetastic",
              "https://www.linkedin.com/company/timetastic/",
              "https://vimeo.com/timetastic",
            ],
            "@type": "SoftwareApplication",
            name: "Timetastic",
            operatingSystem: "Android, iOS",
            applicationCategory: "BusinessApplication",
            offers: {
              "@type": "Offer",
              price: "0",
            },
          }),
        }}
      ></script>
    </div>
  )
}

export default Home

export const query = graphql`
  query {
    allBlogPosts {
      nodes {
        custom_excerpt
        id
        title
        url
        uuid
      }
    }
    placeholderImage: file(
      relativePath: { eq: "../images/hero-blobs-landscape.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 100, formats: [AUTO, WEBP])
      }
    }
    allReviews {
      nodes {
        comments
        rating
        reviewer {
          first_name
          last_name
        }
        stats {
          average_rating
          total_reviews
        }
        store_review_id
        user_id
      }
    }
  }
`
