/**
 * Timetastic
 * Blogs component
 * 3x blog posts in a card
 * Posts come from hosted Ghost CMS
 */
import React from "react"

// Styles
import "./blogs.scss"

// JSX
const Blogs = (posts) => {
  return (
    <section className="c-section c-blogs">
      <div className="u-inner u-inner--l">
        <div className="is-unanimated">
          <h2 className="h h2 u-text-centre-desktop">
            <strong className="u-color-cherry">Learn more</strong> on the blog
          </h2>
        </div>
        {posts && (
          <ul className="c-card-list">
            {posts.posts.map((post) => (
              <li className="c-card is-unanimated" key={`post-${post.uuid}`}>
                <a
                  href={post.url}
                  className="c-card__head"
                  aria-hidden="true"
                  tabIndex="-1"
                >
                  {/*   <img
                    className="c-card__image"
                    src={post.feature_image}
                    alt=""
                  /> */}
                </a>
                <div className="c-card__body">
                  <div className="c-card__body-flex">
                    <h3
                      className="h h4 c-card__title"
                      id={`post-title-${post.uuid}`}
                    >
                      <a
                        href={post.url}
                        aria-hidden="true"
                        className=""
                        tabIndex="-1"
                      >
                        {post.title}
                      </a>
                    </h3>
                    <p className="c-card__excerpt">{post.custom_excerpt}.</p>
                  </div>
                  <div>
                    <a
                      aria-labelledby={`post-title-${post.uuid}`}
                      className="c-card__more"
                      href={post.url}
                    >
                      Read{" "}
                      <svg
                        aria-hidden="true"
                        className="c-more-icon"
                        focussable="false"
                        height="16"
                        viewBox="0 0 20 16"
                        width="20"
                      >
                        <use xlinkHref="#svg-arrow" />
                      </svg>
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  )
}

export default Blogs
